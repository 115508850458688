<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
           <template v-slot:body>
               <b-overlay :show="loading">
                   <div class="text-center" id="printMe">
                    <vue-qrcode :value="getQrCodeContent()" :width="getQrCodeFitWidth()"/>
                   </div>
               </b-overlay>
           </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { allocationDistributionQrcodeAPi } from '../../api/routes'
import flatpickr from 'flatpickr'
import VueQrcode from 'vue-qrcode'
import Vue from 'vue'
import VueHtmlToPaper from 'vue-html-to-paper'
Vue.use(VueHtmlToPaper)

export default {
    props: ['id'],
    components: {
        VueQrcode
    },
    created () {
        if (this.id) {
            this.getData()
        }
    },
    data () {
        return {
            incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
            loader: true,
            loading: false,
            data: {},
            pageStyle: {
                styles: [
                    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
                    `${incentiveGrantServiceBaseUrl}/custom.css`
                ]
            }
    }
    },
    mounted () {
        core.index()
        flatpickr('#registration_date', {})
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        },
        localeCheck: function () {
            return this.$i18n.locale
        }
    },
    methods: {
        getColumnName (list, groupId) {
            const obj = list.find(item => item.value === groupId)
            if (typeof obj !== 'undefined') {
                return obj.text_bn
            } else {
                return ''
            }
        },
        async getData () {
            this.loading = true
            const result = await RestApi.getData(incentiveGrantServiceBaseUrl, `${allocationDistributionQrcodeAPi}/${this.id}`)
            if (result.success) {
                this.data = result.data
            }
            this.loading = false
        },
        getInstrumentName (id) {
            const obj = this.$store.state.incentiveGrant.commonObj.instrumentList.find(item => item.value === id)
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        getBrandName (id) {
            const obj = this.$store.state.incentiveGrant.commonObj.macBrandList.find(item => item.value === id)
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        getUpazilaName (upazilaId) {
        const obj = this.$store.state.commonObj.upazilaList.find(el => el.value === parseInt(upazilaId))
        if (obj !== 'undefinded') {
            return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
        }
        },
        getUnionName (unionId) {
            const obj = this.$store.state.commonObj.unionList.find(el => el.value === parseInt(unionId))
            if (obj !== 'undefinded') {
                return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
            }
        },
        getCountryOriginName (id) {
            const obj = this.$store.state.commonObj.countryList.find(el => el.value === parseInt(id))
            if (obj !== 'undefinded') {
                return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
            }
        },
        getCountryManufactureName (id) {
            const obj = this.$store.state.commonObj.countryList.find(el => el.value === parseInt(id))
            if (obj !== 'undefinded') {
                return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
            }
        },
        getQrCodeContent () {
            let content
            if (this.currentLocale === 'en') {
                content = 'Farmer Information:\n' + this.$t('globalTrans.name', 'en') + ' : ' + this.data.machinery_farmer?.name + '\n' + this.$t('globalTrans.father_name', 'en') + ' : ' + this.data.machinery_farmer?.father_name + '\n' + this.$t('globalTrans.mobile', 'en') + ' : ' + this.data.machinery_farmer?.mobile_no + '\n' + this.$t('globalTrans.nid', 'en') + ' : ' + this.data.machinery_farmer?.nid_no + '\n' + this.$t('globalTrans.upazila', 'en') + ' : ' + this.getUpazilaName(this.data.machinery_farmer?.upazilla_id) + '\n' + this.$t('globalTrans.union', 'en') + ' : ' + this.getUnionName(this.data.machinery_farmer?.union_id) + '\n\n' + 'Allocated Instrument Information: \n' + this.$t('farm_allocation.supplier', 'en') + ' : ' + this.data.supplier_machine?.supplier?.company_name + '\n' + this.$t('farm_config.brand', 'en') + ' : ' + this.getBrandName(this.data.supplier_machine?.brand_id) + '\n' + this.$t('farm_config.country_origin_name', 'en') + ' : ' + this.getCountryOriginName(this.data.supplier_machine?.county_origin_id) + '\n' + this.$t('farm_config.country_prepared_name', 'en') + ' : ' + this.getCountryManufactureName(this.data.supplier_machine?.county_manufacturer_id) + '\n' + this.$t('farm_allocation.model', 'en') + ' : ' + this.data.supplier_machine?.model + '\n' + this.$t('farm_allocation.extend_model', 'en') + ' : ' + this.data.supplier_machine?.extend_model + '\n' + this.$t('farm_allocation.engine_power', 'en') + ' : ' + this.data.supplier_machine?.engine_power + '\n' + this.$t('farm_allocation.engine_no', 'en') + ' : ' + this.data.supplier_machine?.engine_no + '\n' + this.$t('farm_allocation.chasis_no', 'en') + ' : ' + this.data.supplier_machine?.chasis_no + '\n' + this.$t('farm_allocation.distributed_date', 'en') + ' : ' + this.EngBangNum(this.data.distribution?.distributed_date)
            } else {
                content = 'কৃষকের তথ্য:\n' + this.$t('globalTrans.name', 'bn') + ' : ' + this.data.machinery_farmer?.name_bn + '\n' + this.$t('globalTrans.father_name', 'bn') + ' : ' + this.data.machinery_farmer?.father_name_bn + '\n' + this.$t('globalTrans.mobile', 'bn') + ' : ' + this.data.machinery_farmer?.mobile_no + '\n' + this.$t('globalTrans.nid', 'bn') + ' : ' + this.data.machinery_farmer?.nid_no + '\n' + this.$t('globalTrans.upazila', 'bn') + ' : ' + this.getUpazilaName(this.data.machinery_farmer?.upazilla_id) + '\n' + this.$t('globalTrans.union', 'bn') + ' : ' + this.getUnionName(this.data.machinery_farmer?.union_id) + '\n\n' + 'বরাদ্দকৃত যন্ত্রের তথ্য: \n' + this.$t('farm_allocation.supplier', 'bn') + ' : ' + this.data.supplier_machine?.supplier?.company_name_bn + '\n' + this.$t('farm_config.brand', 'bn') + ' : ' + this.getBrandName(this.data.supplier_machine?.brand_id) + '\n' + this.$t('farm_config.country_origin_name', 'bn') + ' : ' + this.getCountryOriginName(this.data.supplier_machine?.county_origin_id) + '\n' + this.$t('farm_config.country_prepared_name', 'bn') + ' : ' + this.getCountryManufactureName(this.data.supplier_machine?.county_manufacturer_id) + '\n' + this.$t('farm_allocation.model', 'bn') + ' : ' + this.data.supplier_machine?.model + '\n' + this.$t('farm_allocation.extend_model', 'bn') + ' : ' + this.data.supplier_machine?.extend_model + '\n' + this.$t('farm_allocation.engine_power', 'bn') + ' : ' + this.data.supplier_machine?.engine_power + '\n' + this.$t('farm_allocation.engine_no', 'bn') + ' : ' + this.data.supplier_machine?.engine_no + '\n' + this.$t('farm_allocation.chasis_no', 'bn') + ' : ' + this.data.supplier_machine?.chasis_no + '\n' + this.$t('farm_allocation.distributed_date', 'bn') + ' : ' + this.EngBangNum(this.data.distribution?.distributed_date)
            }
            return content
        },
        getQrCodeFitWidth () {
            return this.$i18n.locale === 'bn' ? 520 : 520
        },
        pdfExport () {
            this.$htmlToPaper('printMe', this.pageStyle)
        },
        EngBangNum (n) {
            if (n) {
                if (this.$i18n.locale === 'bn') {
                    return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
                } else {
                    return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
                }
            }
        }
    }
}
</script>
